import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tip"];

  connect() {
    console.log("tooltip connected");
  }

  showTip() {
    this.tipTarget.classList.remove("hidden");
  }

  hideTip() {
    this.tipTarget.classList.add("hidden");
  }


}
