import { Controller } from "stimulus"
import Dropzone from 'dropzone'

export default class extends Controller {
  connect() {
    Dropzone.autoDiscover = false;
    Dropzone.options[this.element.id] = {
      acceptedFiles: ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
      maxFilesize: 1,
      paramName: 'upload[file]',
      maxFilesize: 5000,
      success: (dz,response) => {
        if (response.mapURL) {
          Turbolinks.clearCache()

          Turbolinks.visit(response.mapURL);
        } else {
          alert("The file that you uploaded did not look like a Tesla charging history CSV file.");
        }
      }
    }

    var myDropzone = new Dropzone(`#${this.element.id}`);
  }

}
